import React, { useEffect } from "react"
import queryString from "query-string"

import { useIntl } from "gatsby-plugin-react-intl"

//Gatsby imports
import { graphql } from "gatsby"

//React components imports
import Layout from "../../components/Layout"
import SearchResults from "../../components/SearchResults"

//Styled components imports
import Title from "../../shared-styled-components/Title"

const SearchPage = ({ location, data, pageContext }) => {
  const intl = useIntl()

  const qs = queryString.parse(location.search)
  const searchTerm = qs.s

  const allProductsCs = pageContext.dataCs.products.edges.reduce(
    (accumulator, node) => {
      return accumulator.concat(node.node.products.edges)
    },
    []
  )

  const allProductsEn = pageContext.dataEn.products.edges.reduce(
    (accumulator, node) => {
      return accumulator.concat(node.node.products.edges)
    },
    []
  )

  const filteredProductsEn = allProductsEn.filter(product => {
    return (
      product.node.title.toLowerCase().indexOf(searchTerm?.toLowerCase()) >= 0
    )
  })

  const filteredProductsCs = allProductsCs.filter(product => {
    return (
      product.node.title.toLowerCase().indexOf(searchTerm?.toLowerCase()) >= 0
    )
  })

  useEffect(() => {
    if (typeof window.gtag !== "undefined") {
      console.log("send event", queryString.parse(location.search).s)
      window.gtag("event", "search", {
        search_term: queryString.parse(location.search).s,
      })
    }
  }, [location.search])

  const getFilteredCategories = () => {
    const filteredCategories = data.allCollections.edges.filter(category => {
      return (
        category.node.title.toLowerCase().indexOf(searchTerm?.toLowerCase()) >=
        0
      )
    })

    return filteredCategories
  }

  // const getFilteredArticles = () => {
  //   const filteredArticles = data.allArticles.edges.filter(article => {
  //     return (
  //       article.node.title.toLowerCase().indexOf(searchTerm?.toLowerCase()) >= 0
  //     )
  //   })

  //   return filteredArticles
  // }

  const getFilteredProducts = () => {
    const filteredProducts = data.allProducts.edges.filter(product => {
      return filteredProductsCs.some(filteredProduct => {
        return product.node.handle === filteredProduct.node.handle
      })
    })

    return filteredProducts
  }

  const getFilteredProductsEn = () => {
    const filteredProducts = data.allProducts.edges
      .filter(product => {
        return filteredProductsEn.some(filteredProduct => {
          return product.node.handle === filteredProduct.node.handle
        })
      })
      .map(product => {
        const enTitle = filteredProductsEn.find(
          filteredProduct => product.node.handle === filteredProduct.node.handle
        )?.node.title

        return {
          ...product,
          enTitle: enTitle,
        }
      })

    return filteredProducts
  }

  return (
    <Layout subtitle={"Vyhledávání"}>
      <Title>Vyhledávání</Title>
      {intl.locale === "en" ? (
        <SearchResults
          searchTerm={searchTerm}
          products={getFilteredProductsEn()}
          categories={getFilteredCategories()}
          // articles={getFilteredArticles()}
        />
      ) : (
        <SearchResults
          searchTerm={searchTerm}
          products={getFilteredProducts()}
          categories={getFilteredCategories()}
          // articles={getFilteredArticles()}
        />
      )}
    </Layout>
  )
}

export default SearchPage

export const query = graphql`
  query SearchQuery {
    allProducts: allShopifyProduct {
      edges {
        node {
          id
          handle
          title
          priceRangeV2 {
            minVariantPrice {
              amount
            }
          }
          images {
            gatsbyImageData(width: 350)
          }
        }
      }
    }
    allCollections: allShopifyCollection {
      edges {
        node {
          id
          handle
          title
        }
      }
    }
  }
`
//   allArticles: allShopifyArticle {
//     edges {
//       node {
//         id
//         handle
//         title
//       }
//     }
//   }
