import React from "react"

import { useIntl } from "gatsby-plugin-react-intl"

//Styled components imports
import ResultsText from "./ResultsText"

//React components imports
import Categories from "./Categories"
// import Articles from "./Articles"
import Products from "./Products"

const SeachResults = ({ searchTerm, products, categories, articles }) => {
  const intl = useIntl()

  return (
    <div>
      <ResultsText>{`${intl.formatMessage({
        id: "search.results",
      })} "${searchTerm}"`}</ResultsText>
      <hr />
      <Categories categories={categories} />
      {/* <Articles articles={articles} /> */}
      <Products products={products} />
    </div>
  )
}

export default SeachResults
