import styled from "styled-components"

const List = styled.ul`
  list-style-type: disc;
  padding-left: 22px;
  display: grid;

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
  }
`

export default List
