import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"


const Image = styled(GatsbyImage)`
  @media (${props => props.theme.mediaQueries.desktop}) {
    height: 10vw;
  }
`

export default Image
