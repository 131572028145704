import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import List from "./List"

import { useIntl } from "gatsby-plugin-react-intl"

//React components imports
import ProductItem from "../../../templates/ProductsTemplate/ProductItem"

const Products = ({ products }) => {
  const intl = useIntl()

  const handleProducts = () => {
    if (products.length === 0) {
      return (
        <div>
          {intl.formatMessage({
            id: "search.products_not_found",
          })}
        </div>
      )
    }

    return (
      <List>
        {products.map(product => {
          return (
            <ProductItem
              data={product.node}
              title={product.enTitle ? product.enTitle : product.node.title}
            />
          )
        })}
      </List>
    )
  }
  return <Wrapper>{handleProducts()}</Wrapper>
}

export default Products
