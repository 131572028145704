import React from "react"

import { useIntl } from "gatsby-plugin-react-intl"

//Styled components imports
import Wrapper from "./Wrapper"
import Heading from "./Heading"
import List from "./List"
import Item from "./Item"
import Link from "./Link"

const Categories = ({ categories }) => {
  const intl = useIntl()
  const handleCategories = () => {
    if (categories.length === 0) {
      return (
        <p>
          {intl.formatMessage({
            id: "search.categories_not_found",
          })}
        </p>
      )
    }
    return (
      <List>
        {categories.map(category => {
          const handle = category.node.title.includes("/")
            ? category.node.handle.replace("-", "/")
            : category.node.handle
          return (
            <Item>
              <Link to={`/${handle}`}>{category.node.title}</Link>
            </Item>
          )
        })}
      </List>
    )
  }
  return (
    <Wrapper>
      <Heading>
        {" "}
        {intl.formatMessage({
          id: "search.categories_title",
        })}
      </Heading>
      {handleCategories()}
    </Wrapper>
  )
}

export default Categories
