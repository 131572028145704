import styled from "styled-components"

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
`

export default List
