import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import { Media } from "../../../utils/Media"

import Wrapper from "./Wrapper"
import Info from "./Info"
import Image from "./Image"
import Text from "./Text"
import Button from "./Button"

const ProductItem = ({ data, title }) => {
  const intl = useIntl()

  return (
    <Wrapper to={`/produkt/${data.handle}`}>
      <Image image={data.images[0]?.gatsbyImageData} alt={data.title} />
      <Info>
        <Text upper>{title}</Text>
        <Text>
          {intl.formatMessage({
            id: "product_item.from",
          })}
          &nbsp;{Math.round(data.priceRangeV2.minVariantPrice.amount)},- Kč
        </Text>

        <Media greaterThanOrEqual="tablet">
          <Button>Detail</Button>
        </Media>
      </Info>
    </Wrapper>
  )
}

export default ProductItem
