import styled from "styled-components"
import { Link } from "gatsby-plugin-react-intl"

const Wrapper = styled(Link)`
  background: #171717;
  transition: all 0.3s ease-in-out;

  &:hover {
    div:first-child {
      opacity: 0.8;
    }

    @media (${props => props.theme.mediaQueries.tablet}) {
      p {
        display: none;
      }
      div > button {
        display: block;
      }
    }
  }
`

export default Wrapper
