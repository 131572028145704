import styled from "styled-components"

const Button = styled.button`
  font-size: 1.1em;
  max-width: 150px;
  width: 100%;
  padding: 5px;
  margin: 5px auto;
  color: ${props => props.theme.colors.text.white};
  border: 1px solid ${props => props.theme.colors.text.white};
  background: transparent;
  text-transform: uppercase;
  cursor: pointer;
  display: none;
`

export default Button
