import styled from "styled-components"

const Text = styled.p`
  font-size: 0.6em;
  text-align: center;
  text-transform: ${props => props.upper && "uppercase"};

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 0.7em;
  }
`

export default Text
