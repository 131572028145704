import styled from "styled-components"

const Info = styled.div`
  padding: 10px;
  display: grid;
  grid-gap: 15px;
  align-items: center;
  height: auto;
  min-height: 180px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    padding: 20px;
  }
`

export default Info
